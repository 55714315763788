import React, { useState } from "react";
import Layout from "../Layout";
// import image1 from "../../images/icons/image1.png";
// import image2 from "../../images/icons/image2.png";
import { Link, graphql, navigate } from "gatsby";
import moment from "moment";
// import Pagination from "../pagination";
import DownloadAppBar from "../Layout/DownloadAppBar";
import SearchBar from "../search";
import debounce from "lodash/debounce";
import EllipsePagination from "../ellipsePagination";
import LeftArrow from "../../assets/svg/LeftArrow";
import RightArrow from "../../assets/svg/RightArrow";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { SHOW_ANNOUCEMENT_BANNER } from "../../utils/constant";
import PLACE_HOLDER_IMAGE from "../../images/PlantPlaceholder.png";
import { LazyLoadImage } from "react-lazy-load-image-component";

const Blogs = ({ data, pageContext }) => {
  const posts = data?.allGraphCmsPost?.edges?.map((edge) => {
    return edge?.node;
  });
  const [blogs, setBlogs] = useState(posts || []);
  const debounced = debounce((e) => {
    const search = e?.target?.value?.toLowerCase();
    if (search) {
      const allPosts = data?.allBlogs?.edges?.map((edge) => {
        return edge?.node;
      });
      const searchResult = allPosts?.filter((x) =>
        x?.title?.toLowerCase()?.includes(search)
      );
      setBlogs(searchResult);
    } else {
      setBlogs(posts);
    }
  }, 500);

  const onNumberClick = (number) => {
    if (number === 1) {
      navigate(`/blogs/`);
    } else {
      navigate(`/blogs/${number}/`);
    }
  };

  return (
    <Layout title="Flora | Blogs" className="flex flex-col items-center">
      <div className="flex flex-col items-center">
        <div
          className={`w-full xl:w-[1200px] ${
            SHOW_ANNOUCEMENT_BANNER ? "mt-28 md:mt-36" : "mt-20 md:mt-28"
          } `}
        >
          <DownloadAppBar />
          <div className="h-100vh bg-white  mt-10">
            <div className="px-7 flex  justify-start items-center text-3xl font-avenir800 md:text-5xl  md:flex md:justify-center md:pt-5">
              Check our blog
            </div>

            <div className="flex justify-between items-center w-full  py-[5px] px-5 h-[100px]  w-100  md:max-lg:px-0 ">
              <SearchBar placeHolder={"Search"} searchHandler={debounced} />
            </div>
            {blogs?.length ? (
              <div className="w-full p-5 grid grid-cols-1  md:grid-cols-2 gap-[1.5rem] lg:grid-cols-4 gap-6 md:px-5">
                {blogs.map((post, index) => {
                  const blogCoverImage = getImage(post?.coverImage);
                  return (
                    <Link
                      role="button"
                      tabIndex="0"
                      key={index}
                      to={`/blogs/${post?.slug?.toLowerCase()}/`}
                      // onClick={() =>
                      //   navigate(`/blogs/${post?.slug?.toLowerCase()}/`)
                      // }
                      className="cursor-pointer"
                    >
                      {blogCoverImage ? (
                        <GatsbyImage
                          tabIndex="0"
                          image={blogCoverImage}
                          className="w-80 h-48 rounded-xl object-cover md:w-[16rem] hover:scale-95 transition duration-200 cursor-pointer"
                          alt={
                            post?.originalImage?.fileName || "blog-feature-img"
                          }
                        />
                      ) : (
                        <LazyLoadImage
                          src={PLACE_HOLDER_IMAGE}
                          alt={"blog-feature-img"}
                          effect="opacity"
                          className="w-80 h-48 rounded-xl object-cover md:w-[16rem] hover:scale-95 transition duration-200 cursor-pointer"
                        />
                      )}

                      <p
                        tabIndex="0"
                        className="text-[#2D3344] font-avenir900 font-extrabold  text-2xl mt-3 hover:underline"
                      >
                        {post?.title}
                      </p>
                      <p
                        tabIndex="0"
                        className="text-[#2D3344] font-light mt-2"
                      >
                        {moment(post?.publishedAt).format("MMM DD, YYYY")}
                      </p>
                    </Link>
                  );
                })}
              </div>
            ) : (
              <div className="w-full flex justify-center align-center pb-10">
                No articles found.
              </div>
            )}
          </div>

          {/* <Pagination
        totalPages={pageContext?.totalPages}
        currentPage={pageContext?.currentPage}
      /> */}

          <div className="mt-10 mb-10 flex flex-row justify-between text-base text-avenir400 px-5 font-bold md:text-xl">
            <button
              onClick={() =>
                navigate(
                  `/blogs${
                    pageContext.currentPage - 1 === 1
                      ? ""
                      : `/${pageContext.currentPage - 1}`
                  }`
                )
              }
              disabled={pageContext?.currentPage === 1 ? true : false}
              className={`flex justify-center items-center bg-[#E7F6FB] rounded-2xl text-[#07BBE3] py-2 px-5 cursor-pointer w-32 ${
                pageContext?.currentPage === 1 ? "invisible" : "visible"
              }`}
            >
              <LeftArrow />
              Prev
            </button>
            <div className="hidden sm:block	">
              <EllipsePagination
                currentPage={pageContext?.currentPage}
                totalPages={pageContext?.totalPages}
                onNumberClick={onNumberClick}
              />
            </div>
            <Link
              to={`/blogs/${pageContext?.currentPage + 1}`}
              className={`flex justify-center items-center	bg-[#E7F6FB] rounded-2xl text-[#07BBE3] py-2 px-6 cursor-pointer w-32 ${
                pageContext?.currentPage === pageContext?.totalPages
                  ? "invisible"
                  : "visible"
              }`}
            >
              Next
              <RightArrow />
            </Link>
          </div>
        </div>
      </div>
      <div className="fixed w-full z-10 " style={{ bottom: "12px" }}>
        <DownloadAppBar className="my-0 mx-auto" />
      </div>
    </Layout>
  );
};

//GraphQl query to fetch homepage data
export const query = graphql`
  query ($skip: Int, $limit: Int) {
    allGraphCmsPost(
      skip: $skip
      limit: $limit
      sort: { fields: publishedAt, order: DESC }
    ) {
      edges {
        node {
          title
          slug
          date
          publishedAt
          originalImage: coverImage {
            fileName
          }
          coverImage {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
      }
    }
    allBlogs: allGraphCmsPost {
      edges {
        node {
          title
          slug
          date
          publishedAt
          originalImage: coverImage {
            fileName
          }
          coverImage {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
      }
    }
  }
`;

export default Blogs;
